@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Heebo";
  src: url("assets/fonts/Heebo-VariableFont_wght.ttf");
  font-display: swap;
}

@layer base {
  body {
    @apply bg-background font-Heebo;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 400;
  }

  h1 {
    line-height: 120%;
    @apply text-[48px] xl:text-[80px];
  }

  h2 {
    line-height: 150%;
    @apply text-[37px] lg:text-[61px];
  }

  h3 {
    line-height: 150%;
    @apply text-[28px] lg:text-[47px];
  }

  h4 {
    line-height: 150%;
    @apply text-[21px] lg:text-[36px];
  }

  h5 {
    line-height: 150%;
    @apply text-[16px] lg:text-[27px];
  }

  h6 {
    line-height: 150%;
    @apply text-[12px] lg:text-[21px];
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  td,
  th {
    @apply p-[10px] text-sm md:text-md lg:text-lg;
  }

  th {
    @apply text-white;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gradient-to-br from-[#D9D9D9] to-[#D9D9D9] rounded-full h-[363px];
  }

  ::-webkit-scrollbar {
    @apply w-[5px] h-2 bg-background;
  }
}

@layer components {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .checkbox {
    @apply w-4 h-4 mr-2 cursor-pointer appearance-none checked:bg-green text-green-600 bg-gray-100 border-gray-300 rounded dark:ring-offset-gray-800 dark:bg-gray-100 dark:border-gray-600;
  }
}
